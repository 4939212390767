/* You can add global styles to this file, and also import other style files */
@import '~@gm2/ui-library/scss/index';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons'); // Required for material icons
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700');
@import "~ng-pick-datetime/assets/style/picker.min.css";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'PT Sans', sans-serif;
}

.mat-grid-tile .mat-figure {
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

mat-card.form-group {
    width: 392px;
    margin: 16px 0 0 16px;

    mat-form-field {
        display: block;
    }
}

mat-radio-group {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
}

mat-radio-button {
    margin: 5px;
}

mat-expansion-panel.notes {
    .mat-expansion-panel-content {
        height: 150px;
        overflow-y: scroll;
    }

    mat-list {
        mat-list-item {
            .mat-list-item-content {
                padding-top: 8px !important;
                padding-bottom: 8px !important;

                .mat-line {
                    white-space: normal !important;
                }
            }
        }
    }
}

mat-form-field.note-field {
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
}

.mat-checkbox-label {
    white-space: normal;
}

rfx-grid-detail-view,
rfx-grid-filter {
    overflow-y: auto;
}

/*
    Globally hide add tags button.
    TODO: remove this when tags are implemented
*/
rfx-grid-action-bar-control[mattooltip='Add Tags'] {
    display: none;
}

/* Spinner in button */
button.spinner {
    .mat-button-wrapper {
        display: flex;
        align-items: center;

        mat-spinner {
            width: 20px !important;
            height: 20px !important;
            margin-right: 10px;

            svg {
                width: 20px !important;
                height: 20px !important;

                circle {
                    stroke: white;
                }
            }
        }
    }
}
