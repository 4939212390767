@use '@angular/material' as mat;
@import '~saturn-datepicker/theming';
@import '../../../../node_modules/@angular/material/theming';
@include mat.core();
@import 'variables';

$onegm-app-primary: mat.define-palette($onegm-green);
$onegm-app-accent: mat.define-palette($onegm-black);
$onegm-app-warn: mat.define-palette(mat.$red-palette);
$onegm-app-theme: mat.define-light-theme($onegm-app-primary, $onegm-app-accent, $onegm-app-warn);

.onegm-dark-theme {
    // Future dark theme support?
}

@include mat.all-component-themes($onegm-app-theme);
@include sat-datepicker-theme($onegm-app-theme);

* {
    font-family: var(--onegm-font-family);
}

.mat-nav-list .mat-list-item:hover {
    background: rgba(0, 0, 0, 0.1);
}

.flex-spacer {
    flex: 1;
}

mat-spinner.csv-spinner {
    display: inline-block;
    margin-right: 5px;

    &.white {
        circle {
            stroke: white;
        }
    }
}
.inline-spinner circle {
    stroke: white;
}

// GM2 Grid Specific Styles
[rfxgriddetailviewheader] {
    display: flex;
    justify-content: space-between;
    margin: 0 -16px;
    padding: 0 16px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .detail-view {
        &__button {
            min-width: 100px;
        }
    }
}

[rfxgriddetailviewcontent] {
    padding: 12px 0;

    .detail-view {
        &__title {
            margin: 0;
        }

        &__group {
            padding-left: 12px;
            padding-bottom: 4px;

            label {
                font-weight: bold;
                padding-right: 4px;
            }
        }
    }
}

[rfxgriddetailviewfooter] {
    display: flex;
    justify-content: space-between;
    margin: 0 -16px;
    padding: 20px 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    .detail-view {
        &__button {
            min-width: 100px;
        }
    }
}
