$onegm-green: (50: #e9eee5,
    100: #c8d6be,
    200: #a4ba93,
    300: #809e68,
    400: #648a47,
    500: #497527,
    600: #426d23,
    700: #39621d,
    800: #315817,
    900: #21450e,
    A100: #a6ff7e,
    A200: #83ff4b,
    A400: #60ff18,
    A700: #4ffd00,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text));

$onegm-black: (50: #e7e7e7,
    100: #c4c4c4,
    200: #9c9c9c,
    300: #747474,
    400: #575757,
    500: #393939,
    600: #333333,
    700: #2c2c2c,
    800: #242424,
    900: #171717,
    A100: #ef7171,
    A200: #ea4343,
    A400: #fa0000,
    A700: #e00000,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text));

:root {
    // Root CSS Variables should be defined here
    --onegm-color-green: #497527;
    --onegm-color-brown: #795548;
    --onegm-color-black: #393939;
    --onegm-color-white: #FFFFFF;
    --onegm-color-red: #f44336;
    --onegm-color-dark: #333333;
    --onegm-color-dark-rgb: 51, 51, 51;
    --onegm-color-grey: #535353;
    --onegm-color-light-grey: #D4D4D4;

    --onegm-color-primary: #497527;
    --onegm-color-primary-rgb: 73, 117, 39;
    --onegm-color-accent: #393939;
    --onegm-color-accent-rgb: 57, 57, 57;
    --onegm-color-warn: #f44336;
    --onegm-color-text: #333333;
    --onegm-color-text-rgb: 51, 51, 51;
    --onegm-color-yellow: #fee074;
    --onegm-color-orange: #f3953d;

    // Secured Container
    --secured-container-max-mobile: 767px;
    --secured-header-height: 92px;
    --secured-header-profile-bar-height: 52px;
    --secured-sidenav-width: 212px;
    --secured-sidenav-mini-width: 50px;
    --secured-sidenav-transition-time: 0.22s;

    --transition-animation: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}
